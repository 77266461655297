import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {configKeys} from '../workflow.config-keys';
import {Questionnaire, Workflow, WorkflowInstance} from '@ngmedax/common-questionnaire-types';
import {SurveyResult} from "../../../types";

@Injectable()
export class WorkflowService {
  /**
   * Injects dependencies
   */
  public constructor(
    private registryService: RegistryService,
    private configService: ConfigService,
    private http: HttpClient
  ) {}

  /**
   * Gets workflows
   */
  public loadWorkflowInstances(filter?: any, opts?: any): Promise<{ rows: WorkflowInstance[]; total: number }> {
    const query: any = { filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}' };
    const basePath = `${this.getWorkflowApiUrl('/instance')}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          const workflows = result.workflowInstance || result.rows || result || [];
          const total = result.total || 0;
          resolve({ rows: workflows, total });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Gets workflows
   */
  public loadWorkflows(filter?: any, opts?: any): Promise<{ rows: Workflow[]; total: number }> {
    const query: any = { filter: JSON.stringify(filter) || '{}', opts: JSON.stringify(opts) || '{}' };
    const basePath = `${this.getWorkflowApiUrl()}?${decodeURI(new URLSearchParams(query).toString())}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          const workflows = result.workflow || result.rows || result || [];
          const total = result.total || 0;
          resolve({ rows: workflows, total });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Gets workflow by id
   */
  public loadWorkflow(workflowId: string): Promise<Workflow> {
    const basePath = `${this.getWorkflowApiUrl()}/${workflowId}`;

    return new Promise((resolve, reject) => {
      this.http.get(basePath, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          resolve(result.workflow || result || {});
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Creates a new workflow
   */
  public createWorkflow(workflowData: Partial<Workflow>): Promise<Workflow> {
    const basePath = this.getWorkflowApiUrl();

    return new Promise((resolve, reject) => {
      this.http.post(basePath, workflowData, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          resolve(result.workflow || result || {});
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Updates workflow
   */
  public updateWorkflow(workflowId: string, workflowData: Partial<Workflow>): Promise<Workflow> {
    const basePath = `${this.getWorkflowApiUrl()}/${workflowId}`;

    return new Promise((resolve, reject) => {
      this.http.put(basePath, workflowData, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          resolve(result.workflow || result || {});
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Deletes a workflow
   */
  public deleteWorkflow(workflowId: string): Promise<void> {
    const basePath = `${this.getWorkflowApiUrl()}/${workflowId}`;

    return new Promise((resolve, reject) => {
      this.http.delete(basePath, { headers: this.getAuthHeaders() }).subscribe(
        () => {
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Method to load questionnaires.
   *
   * @returns {Promise<Questionnaire[]>}
   */
  public loadQuestionnaires(): Promise<Questionnaire[]> {
    const basePath = this.getQuestionnaireApiUrl() + '?order=meta.title.de_DE&active=true&offset=0&limit=9999999';

    return new Promise((resolve, reject) => {
      this.http.get(basePath, { headers: this.getAuthHeaders() }).subscribe(
        (response: any) => {
          const questionnaires = response.questionnaire || response.rows || response || [];
          resolve(questionnaires);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Creates a new workflow
   */
  public continueWorkflow(clientId: string, workflowInstanceId: string): Promise<SurveyResult> {
    const basePath = this.getPatientApiUrl(`/${clientId}/survey`);
    const body = {
      clientId,
      workflowInstanceId
    }

    return new Promise((resolve, reject) => {
      this.http.post(basePath, body, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          resolve(result.workflow || result || {});
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Updates the external status of a workflow instance
   */
  public updateWorkflowInstanceExternStatus(workflowInstanceId: string, externStatus: string): Promise<WorkflowInstance> {
    const basePath = `${this.getWorkflowApiUrl('/instance')}/${workflowInstanceId}`;
    const body = { externStatus };
    return new Promise((resolve, reject) => {
      this.http.put(basePath, body, { headers: this.getAuthHeaders() }).subscribe(
        (result: any) => {
          resolve(result.workflowInstance || result || {});
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Returns api url for workflow
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getWorkflowApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.WORKFLOW_API_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns api url for questionnaire
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getQuestionnaireApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.QUESTIONNAIRE_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns api url for patient
   *
   * @param {string} suffix
   * @returns {string}
   */
  private getPatientApiUrl(suffix: string = null): string {
    return this.buildUrl(configKeys.PATIENT_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url for config key. Adds auth information to url path when api is not deprecated
   */
  private buildUrl(configKey: string, suffix = null) {
    let uri = this.configService.get(configKey);

    if (suffix) {
      uri = `${uri}${suffix}`;
    }

    return uri;
  }

  /**
   * Returns auth headers by auth token and tenant id
   */
  private getAuthHeaders(): any {
    const headers: any = {};
    const authToken = this.registryService.get(configKeys.SESSION_AUTH_TOKEN);
    const tenantId = this.registryService.get(configKeys.SESSION_TENANT_ID);

    if (authToken) {
      headers['X-Api-Token'] = authToken;
    }

    if (tenantId) {
      headers['X-Api-TenantId'] = `${tenantId}`;
    }

    return headers;
  }
}
