import {Component, OnInit, OnDestroy, Input, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormBuilder, Validators, FormGroup, FormArray, AbstractControl, ValidationErrors} from '@angular/forms';
import {TranslationService, TranslationEventService, Translatable, DateFormatService} from '@ngmedax/translation';
import {ConfigService} from '@ngmedax/config';
import {RegistryService} from '@ngmedax/registry';
import {LayoutService} from '@ngmedax/layout';
import {WorkflowService} from '../services/workflow.service';
import {Workflow} from '@ngmedax/common-questionnaire-types';
import {TRANSLATION_CRUD_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';

export interface WorkflowCrudComponent extends Translatable {}

function atLeastOneQuestionnaire(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (Array.isArray(value) && value.length > 0) {
    return null;
  }
  return {noQuestionnaires: true};
}

@Component({
  selector: 'app-workflow-crud',
  templateUrl: './workflow-crud.component.html',
  styleUrls: ['./workflow-crud.component.css']
})
@Translatable({ scope: TRANSLATION_CRUD_SCOPE, keys: KEYS })
export class WorkflowCrudComponent implements OnInit, OnDestroy {
  @Input() public workflowId: string = null;

  public workflowForm: FormGroup;
  public dirty = true;
  public loading = false;
  public showCloseWinBtn = false;
  private subscriptions: Subscription[] = [];
  public locale = 'de_DE';
  public collapsedStages: boolean[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private workflowService: WorkflowService,
    private configService: ConfigService,
    private registryService: RegistryService,
    private layoutService: LayoutService,
    @Optional() private translationEvents: TranslationEventService,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
  ) {}

  public ngOnInit(): void {
    this.translationService && (this.locale = this.translationService.getLocale());

    this.workflowForm = this.formBuilder.group({
      _id: [null],
      name: this.formBuilder.group({
        de_DE: ['', Validators.required]
      }),
      description: this.formBuilder.group({
        de_DE: ['']
      }),
      stages: this.formBuilder.array([])
    });

    this.activatedRoute.queryParams.subscribe((params: any) => params.closeWinBtn && (this.showCloseWinBtn = true));
    const routeSub = this.activatedRoute.params.subscribe(async (params: any) => {
      if (params.id) {
        this.workflowId = params.id;
        await this.loadWorkflow(params.id);
      } else if (this.workflowId) {
        await this.loadWorkflow(this.workflowId);
      } else {
        this.dirty = false;
      }
    });
    this.subscriptions.push(routeSub);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  get stages(): FormArray {
    return this.workflowForm.get('stages') as FormArray;
  }

  private async loadWorkflow(workflowId: string) {
    try {
      this.loading = true;
      this.layoutService.showPreloader();
      const w = await this.workflowService.loadWorkflow(workflowId);
      if (!w) {
        alert(this._(KEYS.CRUD.FOUND_NO_ENTRY_BY_ID) + ' ' + workflowId);
        return;
      }
      this.renderWorkflow(w);
      this.dirty = false;
      this.layoutService.hidePreloader();
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.layoutService.hidePreloader();
      alert(this._(KEYS.CRUD.ERROR_LOADING_WORKFLOW));
      this.loading = false;
    }
  }

  private renderWorkflow(workflow: Workflow) {
    this.workflowForm.reset();
    this.stages.clear();
    this.collapsedStages = [];

    this.workflowForm.get('_id').setValue(workflow._id || workflow.id || null);

    if (workflow.name) {
      for (const locale of Object.keys(workflow.name)) {
        const ctrl = this.workflowForm.get('name.' + locale);
        ctrl && ctrl.setValue(workflow.name[locale]);
      }
    }

    if (workflow.description) {
      for (const locale of Object.keys(workflow.description)) {
        const ctrl = this.workflowForm.get('description.' + locale);
        ctrl && ctrl.setValue(workflow.description[locale]);
      }
    }

    if (workflow.stages && workflow.stages.length) {
      workflow.stages.forEach((stage, index) => {
        const stageGroup = this.createStageFormGroup();
        if (stage.name) {
          for (const locale of Object.keys(stage.name)) {
            const ctrl = stageGroup.get('name.' + locale);
            ctrl && ctrl.setValue(stage.name[locale]);
          }
        }
        if (stage.description) {
          for (const locale of Object.keys(stage.description)) {
            const ctrl = stageGroup.get('description.' + locale);
            ctrl && ctrl.setValue(stage.description[locale]);
          }
        }

        stageGroup.get('skipSigning').setValue(stage.skipSigning || false);
        stageGroup.get('generateExports').setValue(stage.generateExports || false);
        stageGroup.get('generateFormPDFs').setValue(stage.generateFormPDFs || false);
        stageGroup.get('prefill').setValue(stage.prefill || false);
        stageGroup.get('questionnaires').setValue(stage.questionnaires || []);

        this.stages.push(stageGroup);
        this.collapsedStages.push(true);
      });
    }
  }

  private createStageFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: this.formBuilder.group({
        de_DE: ['']
      }),
      description: this.formBuilder.group({
        de_DE: ['']
      }),
      skipSigning: [false],
      generateExports: [false],
      generateFormPDFs: [false],
      prefill: [false],
      questionnaires: [[], atLeastOneQuestionnaire]
    });
  }

  public onAddStage() {
    this.stages.push(this.createStageFormGroup());
    this.collapsedStages.push(false);
    this.dirty = true;
  }

  public async onRemoveStage(index: number) {
    if (await confirm(this._(KEYS.CRUD.CONFIRM_DELETE_STAGE))) {
      this.stages.removeAt(index);
      this.collapsedStages.splice(index, 1);
      this.dirty = true;
    }
  }

  public async onSubmit() {
    await this.saveWorkflow();
  }

  private async saveWorkflow() {
    try {
      const workflow: Workflow = this.workflowForm.value;
      let savedWorkflow: Workflow;

      if (workflow._id) {
        savedWorkflow = await this.workflowService.updateWorkflow(workflow._id, workflow);
      } else {
        savedWorkflow = await this.workflowService.createWorkflow(workflow);
      }

      this.dirty = false;
      this.renderWorkflow(savedWorkflow);
      alert(this._(KEYS.CRUD.SUCCESSFULLY_SAVED_WORKFLOW));
    } catch (error) {
      console.error(error);
      alert(this._(KEYS.CRUD.ERROR_SAVING_WORKFLOW));
    }
  }

  public onQuestionnairesSelect(questionnaires: string[], stageIndex: number) {
    const stage = this.stages.at(stageIndex);
    stage.get('questionnaires').setValue(questionnaires);
    this.dirty = true;
  }

  public toggleStage(index: number) {
    this.collapsedStages[index] = !this.collapsedStages[index];
  }

  public getStageDisplayName(index: number): string {
    const val = this.stages.at(index).get('name.de_DE').value;
    const name = val && val.trim() ? val.trim() : null;
    if (name) {
      return name;
    }
    return this._(KEYS.CRUD.STAGE) + ' ' + (index + 1);
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
