import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qrcode-modal',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">QR Code</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="modal-body text-center">
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item">
          <a class="nav-link" [class.active]="activeTab === 'mobile'" (click)="activeTab = 'mobile'" href="javascript:void(0)">
            myMedax-App
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="activeTab === 'browser'" (click)="activeTab = 'browser'" href="javascript:void(0)">
            Browser
          </a>
        </li>
      </ul>

      <div [ngSwitch]="activeTab">
        <ng-container *ngSwitchCase="'mobile'">
          <ng-container *ngIf="qrCodeString">
            <qrcode [qrdata]="qrCodeString" [margin]="0" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            <span class="txt-code">{{qrCodeString}}</span>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'browser'">
          <ng-container *ngIf="qrCodeLink">
            <qrcode [qrdata]="qrCodeLink" [margin]="0" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            <span class="txt-code"></span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  `,
  styles: [`
    ngx-qrcode {
      display: inline-block;
      margin-top: 10px;
    }

    .txt-code {
      top: -5px;
      position: relative;
      font-family: monospace;
      font-size: 8.1px;
      color: gray;
    }

    .nav-tabs .nav-link {
      cursor: pointer;
    }
  `]
})
export class QrCodeModalComponent {
  @Input() qrCodeString: string = '';
  @Input() qrCodeLink: string = '';
  activeTab: 'mobile' | 'browser' = 'mobile';

  constructor(private modalService: NgbModal) {}

  closeModal() {
    this.modalService.dismissAll();
  }
}
