import {Component, Input, Optional} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Base64 as base64} from 'js-base64';
import * as localForage from 'localforage';

import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {SettingsService} from '../../services/settings.service';
import {ConfigService} from '@ngmedax/config';

import {QuestionnaireFormatService} from '../../services/questionnaire-format.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface PreviewComponent extends Translatable {}

@Component({
  selector: 'app-qa-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class PreviewComponent {
  @Input() questionnaire: Questionnaire;
  previewUrl: SafeResourceUrl;

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireFormatService: QuestionnaireFormatService,
    private settingsService: SettingsService,
    private configService: ConfigService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer
  ) {
    localForage.config({driver: localForage.INDEXEDDB, name: 'myMedaxPreviewStorage'});
  }

  /**
   * Opens preview for current questionnaire
   *
   * @param content
   */
  public async open(content) {
    let key: string = '';
    if (this.questionnaire) {
      key = base64.encode(this.questionnaire.id);
      const payload = this.questionnaireFormatService.getPayload(this.questionnaire);

      const assetCdnUrl = this.configService.get('apis.asset.cdn');

      const isAutoThemingActive = await this.settingsService.getValue('mobileThemingActive');
      const themeUrl = isAutoThemingActive ? `${assetCdnUrl}/mobile-theme/theme.zip` : this.configService.get('mobile.theme');

      await localForage.clear();
      await localForage.setItem('previewMode', 'true');
      await localForage.setItem(key, base64.encode(JSON.stringify(payload)));

      await localForage.removeItem('themeUrl');
      themeUrl && await localForage.setItem('themeUrl', themeUrl);
      await localForage.setItem('assetCdnUrl', assetCdnUrl);
      const locale = this.translationService ? this.translationService.getLocale() : 'de_DE';

      this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/preview/?key=${key}&locale=${locale}`);
    }

    this.modalService.open(content, {size: 'lg'}).result.finally(() => {
      key && localForage.removeItem(key).catch(e => console.error(e));
    });
  }
}
