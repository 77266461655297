<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.WORKFLOW_INSTANCES)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-status">
            <span class="input-group-addon" style="min-width: 116px">{{_(KEYS.GRID.STATUS)}}:</span>
            <select class="custom-select" [(ngModel)]="filter.status"
                    (blur)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
              <option value="" selected>...</option>
              <option value="created">{{_(KEYS.GRID.STATUS_CREATED)}}</option>
              <option value="submitted">{{_(KEYS.GRID.STATUS_SUBMITTED)}}</option>
            </select>
          </div>

          <div class="input-group qa-grid-search-extern">
            <span class="input-group-addon" style="min-width: 116px">
              {{_(KEYS.GRID.EXTERN)}}:
            </span>
            <select class="custom-select" [(ngModel)]="filter.externStatus"
                    (blur)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
              <option value="" selected>...</option>
              <option value="open">{{_(KEYS.GRID.EXTERN_STATUS_OPEN)}}</option>
              <option value="pending">{{_(KEYS.GRID.EXTERN_STATUS_PENDING)}}</option>
              <option value="done">{{_(KEYS.GRID.EXTERN_STATUS_DONE)}}</option>
            </select>
          </div>

          <div class="input-group qa-grid-search-patient">
            <span class="input-group-addon" style="min-width: 116px">{{_(KEYS.GRID.PATIENT)}}:</span>
            <input class="form-control" [(ngModel)]="filter.patient"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

          <div class="input-group qa-grid-search-workflow">
            <span class="input-group-addon">{{_(KEYS.GRID.WORKFLOW)}}:</span>
            <input class="form-control" [(ngModel)]="filter.workflow"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

          <div class="input-group qa-grid-search-questionnaires">
            <app-select2
              label="{{_(KEYS.GRID.QUESTIONNAIRES)}}: "
              [hideButton]="true"
              [multiple]="true"
              [options]="{width: '100%', placeholder: '...'}"
              [(selected)]="filter.questionnaires"
              (selectedChange)="onFilterChange()"
            >
              <option *ngFor="let q of questionnaires" [value]="q.id">
                {{q.meta.title[locale] || q.meta.title[defaultLocale] || '???'}}
              </option>
            </app-select2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.WORKFLOW_INSTANCES)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="qa-cursor-pointer mini">
              {{_(KEYS.GRID.EXTERN)}}
            </th>
            <th class="id-col qa-cursor-pointer">
              {{_(KEYS.GRID.ID)}}
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('workflow.name.de_DE')">
              {{_(KEYS.CRUD.WORKFLOW)}}
              <i *ngIf="filter.sort === 'workflow.name.de_DE' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'workflow.name.de_DE' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('client.lastName')">
              {{_(KEYS.GRID.PATIENT)}}
              <i *ngIf="filter.sort === 'client.lastName' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'client.lastName' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th>{{_(KEYS.GRID.SUBMISSIONS)}}</th>
            <th>{{_(KEYS.GRID.STAGE)}}</th>
            <th class="qa-cursor-pointer" (click)="onSortChange('status')">
              {{_(KEYS.GRID.STATUS)}}
              <i *ngIf="filter.sort === 'status' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'status' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('createdAt')" style="min-width: 135px;">
              {{_(KEYS.GRID.CREATED_AT)}}
              <i *ngIf="filter.sort === 'createdAt' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'createdAt' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('updatedAt')" style="min-width: 145px;">
              {{_(KEYS.GRID.UPDATED_AT)}}
              <i *ngIf="filter.sort === 'updatedAt' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'updatedAt' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th style="width: 125px;">{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let instance of workflowInstances; let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="mini">
                <select class="extern-status form-control form-control-sm" [(ngModel)]="instance.externStatus"
                        (change)="onExternStatusChange(instance, $event.target.value)">
                  <option value="open">{{_(KEYS.GRID.EXTERN_STATUS_OPEN)}}</option>
                  <option value="pending">{{_(KEYS.GRID.EXTERN_STATUS_PENDING)}}</option>
                  <option value="done">{{_(KEYS.GRID.EXTERN_STATUS_DONE)}}</option>
                </select>
              </td>
              <td class="id-col">
                <span ngbTooltip="{{instance.uid}}">{{instance.uid | truncate: 5}}</span>
              </td>
              <td style="max-width: 200px">
                <a class="badge badge-success"
                   [routerLink]="['/module/workflow/crud', instance.workflow?.id]">
                  {{instance.workflow?.name[locale] || instance.workflow?.name[defaultLocale] || '?'}}
                </a>
                <div class="text-muted small mt-1">
                  <ng-container *ngFor="let stage of instance.workflow?.stages; let sIndex = index;">
                    <ng-container *ngIf="sIndex > 0">, </ng-container>[{{ sIndex + 1 }}]
                    <ng-container *ngIf="stage.questionnaires && stage.questionnaires.length">
                      <ng-container *ngFor="let qId of stage.questionnaires; let pos = index;">
                        <a class="text-muted mt-1" [routerLink]="['/module/questionnaire/editor', qId]">
                          {{getQuestionnaireTitle(qId)}} <ng-container *ngIf="pos < stage.questionnaires.length - 1">, </ng-container>
                        </a>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
              <td>
                <ng-container *ngIf="instance.client">
                  <a class="badge badge-success" [routerLink]="canEditPatient ? ['/module/patient/crud', instance.clientId] : null">
                    <ng-container *ngIf="!instance.client.anonymous && !instance.client.pseudonymous">
                      {{instance.client.firstName}} {{instance.client.lastName}}
                    </ng-container>
                    <ng-container *ngIf="instance.client.anonymous">
                      {{_(KEYS.GRID.ANONYMOUS)}}
                    </ng-container>
                    <ng-container *ngIf="instance.client.pseudonymous">
                      {{_(KEYS.GRID.PSEUDONYMOUS)}} {{instance.client.pseudoId ? '- Pseudo Id: '+instance.client.pseudoId : ''}}
                    </ng-container>
                  </a>
                  <div class="text-muted small mt-1">
                    <ng-container *ngIf="!instance.client.anonymous && !instance.client.pseudonymous">
                      {{_(KEYS.GRID.PATIENT_ID)}} {{instance.client.customerNr}} -
                      {{_(KEYS.GRID.CASE_NR)}} {{instance.client.caseNr}} -
                      *{{fromMySQLDate(instance.client.birthDate)}} - {{instance.client.gender === 'male' ? 'm' : 'w'}}
                      <br>
                      <ng-container *ngIf="instance.client.pseudoId">
                        Pseudo Id - {{instance.client.pseudoId}}<br>
                      </ng-container>
                      <span *ngIf="instance.client.gender === 'male'">{{_(KEYS.GRID.MR)}}</span>
                      <span *ngIf="instance.client.gender === 'female'">{{_(KEYS.GRID.MS)}}</span>
                      {{instance.client.address}} - {{instance.client.location}} - {{instance.client.status}}
                    </ng-container>
                  </div>
                </ng-container>
              </td>
              <td>
                <ng-container *ngFor="let survey of instance.surveys; let surveyPos = index;">
                  <ul *ngIf="survey.submissions">
                    <ng-container *ngFor="let submission of survey.submissions">
                      <li *ngIf="submission?.questionnaire?.meta?.title && submission?.questionnaire?.meta?.title[locale] && submission.exports && submission.exports.length">
                        <button class="btn btn-success btn-sm stage-badge mr-1">{{surveyPos + 1}}</button>
                        <div ngbDropdown class="d-inline-block">
                          <button class="btn btn-primary btn-xs d-inline-block mr-1" ngbDropdownToggle>
                            <i class="fa fa-download"></i>
                          </button>
                          <div ngbDropdownMenu>
                            <button
                              class="dropdown-item" *ngIf="submission.bucketId" (click)="onOpenMediaCenterModal(submission)">
                              <i class="fa fa-window-maximize"></i> {{_(KEYS.GRID.ASSETS)}}
                            </button>
                            <button
                              class="dropdown-item"
                              (click)="onOpenLink(submission.uploadLinks['ownCloud'])"
                              *ngIf="submission.uploadLinks && submission.uploadLinks['ownCloud']"
                            >
                              <i class="fa fa-cloud"></i> ownCloud
                            </button>
                            <hr *ngIf="(submission.bucketId) || (submission.uploadLinks && submission.uploadLinks['ownCloud'])"/>
                            <ng-container *ngIf="hasValidExports(submission)">
                              <ng-container *ngFor="let export of submission.exports">
                                <button class="dropdown-item" (click)="onDownload(export.fileName)" *ngIf="export.type !== 'EXTERN'">
                                  <i class="fa fa-download"></i>&nbsp;&nbsp;{{export && export.type ? _(export.type) : ''}}
                                </button>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                        <span class="text-muted small">{{submission.questionnaire.meta.title[locale]}}</span>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="instance.surveys">
                  <ng-container *ngFor="let survey of instance.surveys; let surveyPos = index;">
                    <button class="btn btn-success btn-sm stage-badge mr-1">{{surveyPos + 1}}</button>
                    <span>
                      <ng-container [ngSwitch]="survey.status">
                        <i *ngSwitchCase="'created'" class="fa fa-plus text-secondary" ngbTooltip="{{_(KEYS.GRID.STATUS_CREATED)+ ' @'}}{{survey.updatedAt | datex:dateFormat}}"></i>
                        <i *ngSwitchCase="'assigned'" class="fa fa-user text-primary" ngbTooltip="{{_(KEYS.GRID.STATUS_ASSIGNED)+ ' @'}}{{survey.updatedAt | datex:dateFormat}}"></i>
                        <i *ngSwitchCase="'submitted'" class="fa fa-check text-success" ngbTooltip="{{_(KEYS.GRID.STATUS_SUBMITTED)+ ' @'}}{{survey.updatedAt | datex:dateFormat}}"></i>
                        <i *ngSwitchCase="'signed'" class="fa fa-pencil text-success" ngbTooltip="{{_(KEYS.GRID.STATUS_SIGNED)+ ' @'}}{{survey.updatedAt | datex:dateFormat}}"></i>
                        <i *ngSwitchCase="'canceled'" class="fa fa-ban text-danger" ngbTooltip="{{_(KEYS.GRID.STATUS_CANCELED)+ ' @'}}{{survey.updatedAt | datex:dateFormat}}"></i>
                        <i *ngSwitchDefault class="fa fa-question text-secondary" ngbTooltip="{{_(KEYS.GRID.STATUS_UNKNOWN)+ ' @'}}{{survey.updatedAt | datex:dateFormat}}"></i>
                      </ng-container>
                    </span><br>
                  </ng-container>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!isExpired(instance) && (!instance.status || instance.status === 'created') && (!instance.surveys && !instance.surveys.length)">
                  <i class="fa fa-plus text-secondary" ngbTooltip="{{_(KEYS.GRID.STATUS_CREATED)}}"></i>
                </ng-container>
                <ng-container *ngIf="!isExpired(instance) && (!instance.status || instance.status === 'created') && instance.surveys && instance.surveys.length">
                  <i class="fa fa-user text-primary" ngbTooltip="{{_(KEYS.GRID.STATUS_ASSIGNED)}}"></i>
                </ng-container>
                <ng-container *ngIf="instance.status === 'submitted'">
                  <i class="fa fa-check text-success" ngbTooltip="{{_(KEYS.GRID.STATUS_SUBMITTED)}}"></i>
                </ng-container>
                <ng-container *ngIf="instance.status === 'canceled'">
                  <i class="fa fa-ban text-danger" ngbTooltip="{{_(KEYS.GRID.STATUS_CANCELED)}}"></i>
                </ng-container>
                <ng-container *ngIf="isExpired(instance)">
                  <i class="fa fa-clock-o text-danger" ngbTooltip="{{_(KEYS.GRID.STATUS_EXPIRED) + ' @'}}{{instance.validUntil | datex:dateFormat}}"></i>
                </ng-container>
              </td>
              <td style="min-width: 135px;">
                {{instance.createdAt | datex:dateFormat}}
              </td>
              <td style="min-width: 135px;">
                {{instance.updatedAt | datex:dateFormat}}
              </td>
              <td class="options">
                <button class="btn btn-sm btn-primary"
                        (click)="onContinueWorkflow(instance)"
                        ngbTooltip="{{_(KEYS.GRID.CONTINUE_WORKFLOW)}}"
                        [disabled]="instance.status === 'canceled' || instance.status === 'submitted' || isExpired(instance)">
                  <i class="fa fa-play"></i>
                </button>
                <button class="btn btn-sm btn-primary ml-1"
                        (click)="onCopyWorkflowContinueLink(instance)"
                        ngbTooltip="{{_(KEYS.GRID.COPY_LINK_TO_CONTINUE)}}"
                        [disabled]="instance.status === 'canceled' || instance.status === 'submitted' || isExpired(instance)">
                  <i class="fa fa-copy"></i>
                </button>
                <button class="btn btn-sm btn-primary ml-1"
                        (click)="onShowQRCode(instance)"
                        ngbTooltip="{{_(KEYS.GRID.SHOW_QR_CODE_TO_CONTINUE)}}"
                        [disabled]="instance.status === 'canceled' || instance.status === 'submitted' || isExpired(instance)">
                  <i class="fa fa-qrcode"></i>
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
