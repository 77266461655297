<div class="workflow-crud">
  <h2 class="mb-4">{{_(KEYS.CRUD.WORKFLOW)}}</h2>

  <form [formGroup]="workflowForm">

    <div class="mb-3" formGroupName="name">
      <label>{{_(KEYS.CRUD.STAGE_NAME)}}</label>
      <input class="form-control" formControlName="de_DE" placeholder="{{_(KEYS.CRUD.ENTER_WORKFLOW_NAME)}}" />
    </div>

    <div class="mb-3" formGroupName="description">
      <label>{{_(KEYS.CRUD.STAGE_DESCRIPTION)}}</label>
      <textarea class="form-control" formControlName="de_DE" placeholder="{{_(KEYS.CRUD.ENTER_WORKFLOW_DESCRIPTION)}}"></textarea>
    </div>

    <hr>

    <h4>{{_(KEYS.CRUD.STAGES)}}</h4>
    <div formArrayName="stages">
      <div *ngFor="let stage of stages.controls; index as i" [formGroupName]="i" class="mb-3 border rounded">

        <div class="p-2 bg-light d-flex justify-content-between align-items-center" style="cursor: pointer;"
             (click)="toggleStage(i)">
          <strong>
            <button class="btn btn-success btn-sm stage-number">{{i+1}}</button>&nbsp;
            <span class="stage-name">{{getStageDisplayName(i)}}</span>
          </strong>
          <i class="fa" [ngClass]="collapsedStages[i] ? 'fa-chevron-right' : 'fa-chevron-down'"></i>
        </div>

        <div class="p-3" *ngIf="!collapsedStages[i]">
          <div formGroupName="name" class="mb-3">
            <label>{{_(KEYS.CRUD.STAGE_NAME)}}</label>
            <input class="form-control" formControlName="de_DE" placeholder="{{_(KEYS.CRUD.ENTER_STAGE_NAME)}}" />
          </div>

          <div formGroupName="description" class="mb-3">
            <label>{{_(KEYS.CRUD.STAGE_DESCRIPTION)}}</label>
            <textarea class="form-control" formControlName="de_DE" placeholder="{{_(KEYS.CRUD.ENTER_STAGE_DESCRIPTION)}}"></textarea>
          </div>

          <div class="form-check flag-option mb-2">
            <input type="checkbox" class="form-check-input" formControlName="skipSigning" id="skipSigning-{{i}}">
            <label class="form-check-label" for="skipSigning-{{i}}">{{_(KEYS.CRUD.SKIP_SIGNING)}}</label>
          </div>

          <div class="form-check flag-option mb-2">
            <input type="checkbox" class="form-check-input" formControlName="generateExports" id="generateExports-{{i}}">
            <label class="form-check-label" for="generateExports-{{i}}">{{_(KEYS.CRUD.GENERATE_EXPORTS)}}</label>
          </div>

          <div class="form-check flag-option mb-2">
            <input type="checkbox" class="form-check-input" formControlName="generateFormPDFs" id="generateFormPDFs-{{i}}">
            <label class="form-check-label" for="generateFormPDFs-{{i}}">{{_(KEYS.CRUD.GENERATE_FORM_PDFS)}}</label>
          </div>

          <div class="form-check flag-option mb-3">
            <input type="checkbox" class="form-check-input" formControlName="prefill" id="prefill-{{i}}">
            <label class="form-check-label" for="prefill-{{i}}">{{_(KEYS.CRUD.PREFILL)}}</label>
          </div>

          <div class="mb-3">
            <label>{{_(KEYS.CRUD.STAGE_QUESTIONNAIRES)}}</label>
            <app-questionnaire-select
              [enableGroups]="true"
              [enableWorkflows]="false"
              [selected]="stage.get('questionnaires').value"
              (selectedChange)="onQuestionnairesSelect($event, i)">
            </app-questionnaire-select>
          </div>

          <button type="button" class="btn btn-danger" (click)="onRemoveStage(i)">
            <i class="fa fa-trash"></i> {{_(KEYS.CRUD.DELETE_STAGE)}}
          </button>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-success mt-2" (click)="onAddStage()">
      <i class="fa fa-plus"></i> {{_(KEYS.CRUD.ADD_STAGE)}}
    </button>

    <hr>

    <div>
      <button type="button" class="btn btn-primary" [disabled]="!workflowForm.valid" (click)="onSubmit()">
        <i class="fa fa-save"></i> {{_(KEYS.CRUD.SAVE_WORKFLOW)}}
      </button>
    </div>
  </form>
</div>
