import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  ElementRef,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DATE_FORMAT_YMD_HM,
  DATE_FORMAT_YMD,
  Translatable,
  TranslationService,
  DateFormatService
} from '@ngmedax/translation';
import {WorkflowInstance, Translation, Questionnaire} from '@ngmedax/common-questionnaire-types';
import {QrCodeModalComponent} from './modals/qrcode-modal.component';
import {LayoutService, EventService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {PermissionService} from '@ngmedax/permission';
import {Router} from '@angular/router';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {WorkflowService} from '../services/workflow.service';
import {SurveyResult} from '../../../types';
import {MediaCenterService} from '@ngmedax/asset';
import {permission} from '../workflow.permission';

export interface WorkflowInstanceGridComponent extends Translatable {}

@Component({
  selector: 'app-workflow-instance-grid',
  templateUrl: './workflow-instance-grid.component.html',
  styleUrls: ['./workflow-instance-grid.component.css']
})
@Translatable({ scope: TRANSLATION_GRID_SCOPE, keys: KEYS })
export class WorkflowInstanceGridComponent implements OnInit {
  public isSearchCollapsed = false;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public workflowInstances: WorkflowInstance[] = [];
  public filter: {
    workflow?: string;
    patient?: string;
    status?: string;
    externStatus?: string;
    sort?: string;
    order?: string;
    questionnaires?: string[];
  } = {
    workflow: '',
    patient: '',
    status: '',
    externStatus: '',
    sort: 'createdAt',
    order: 'desc',
    questionnaires: []
  };
  public total = 0;
  public locale = 'de_DE';
  public defaultLocale = 'de_DE';
  public canEditPatient = false;
  private filterChangeTimeout: any = null;
  private showPreloaderTimeout: any = null;
  private assetsBaseUri = '';

  public questionnaireTitles: { [uid: string]: Translation } = {};
  public questionnaires: Questionnaire[] = [];

  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  }

  constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    @Optional() private permissionService: PermissionService,
    private layoutService: LayoutService,
    private eventService: EventService,
    private workflowService: WorkflowService,
    private ref: ChangeDetectorRef,
    private configService: ConfigService,
    private router: Router,
    private modalService: NgbModal,
    @Optional() private mediaCenterService: MediaCenterService
  ) {
    this.assetsBaseUri = this.configService.get('apis.submission.assets.cdn');
  }

  ngOnInit(): void {
    this.layoutService.showPreloader();
    this.loadWorkflowInstances();
    this.loadQuestionnaires();

    if (this.permissionService) {
      const routeData = {
        needsLogin: true,
        needsPermissions: [
          permission.MYMEDAX_PATIENT_UPDATE,
          permission.MYMEDAX_PATIENT_QUESTIONNAIRE_READ,
          permission.MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN,
          permission.MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN
        ]
      };

      const result = this.permissionService.isAllowedByRouteData(routeData);
      this.canEditPatient = !!result.allowed;
    }
  }

  public onPagingChange() {
    this.ref.detectChanges();
    this.ref.markForCheck();
    this.loadWorkflowInstances();
  }

  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => {
      this.loadWorkflowInstances();
    }, 1000);
  }

  public onSortChange(col: string) {
    if (this.filter.sort === col) {
      this.filter.order = this.filter.order === 'asc' ? 'desc' : 'asc';
    } else {
      this.filter.sort = col;
      this.filter.order = 'asc';
    }
    this.loadWorkflowInstances();
  }

  public async onContinueWorkflow(instance: WorkflowInstance) {
    if (!instance || !instance.clientId || !instance.id) {
      alert(this._(KEYS.GRID.FOUND_NO_ENTRY_BY_ID));
      return;
    }
    try {
      this.layoutService.showPreloader();
      const result: SurveyResult = await this.workflowService.continueWorkflow(
        instance.clientId,
        instance.id
      );
      this.layoutService.hidePreloader();
      if (result && result.link) {
        window.open(result.link, '_blank');
      } else {
        alert(this._(KEYS.GRID.ERROR_LOADING_WORKFLOWS));
      }
    } catch (error) {
      this.layoutService.hidePreloader();
      console.error(error);
      alert(this._(KEYS.GRID.ERROR_LOADING_WORKFLOWS));
    }
  }

  public async onCopyWorkflowContinueLink(instance: WorkflowInstance) {
    if (!instance || !instance.clientId || !instance.id) {
      alert(this._(KEYS.GRID.FOUND_NO_ENTRY_BY_ID));
      return;
    }
    const result: SurveyResult = await this.workflowService.continueWorkflow(
      instance.clientId,
      instance.id
    );
    const url = result && result.link;
    navigator.clipboard
      .writeText(url)
      .then(() => alert(this._(KEYS.GRID.COPY_LINK_SUCCESS)))
      .catch(() => alert(this._(KEYS.GRID.COPY_LINK_ERROR)));
  }

  public async onShowQRCode(instance: WorkflowInstance) {
    if (!instance || !instance.clientId || !instance.id) {
      alert(this._(KEYS.GRID.FOUND_NO_ENTRY_BY_ID));
      return;
    }
    try {
      this.layoutService.showPreloader();
      const result: SurveyResult = await this.workflowService.continueWorkflow(
        instance.clientId,
        instance.id
      );
      this.layoutService.hidePreloader();
      const modalRef = this.modalService.open(QrCodeModalComponent, {
        size: 'lg',
        centered: true
      });

      let qrCode = result.qrCode.assign;

      if (!qrCode.includes('&locale=')) {
        qrCode += `&locale=${result.survey.locale || this.defaultLocale}`;
      }

      modalRef.componentInstance.qrCodeString = qrCode;
      result.link && (modalRef.componentInstance.qrCodeLink = result.link);
    } catch (error) {
      this.layoutService.hidePreloader();
      console.error(error);
      alert(this._(KEYS.GRID.ERROR_LOADING_QR_CODE));
    }
  }

  public fromMySQLDate(date: string): string {
    return this.dateFormatService && date
      ? this.dateFormatService.fromMySqlDate(date, this.getDateFormat(DATE_FORMAT_YMD))
      : date;
  }

  private loadWorkflowInstances() {
    this.showPreloader();
    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();
    this.workflowService
      .loadWorkflowInstances(filter, opts)
      .then(result => {
        this.workflowInstances = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_WORKFLOWS));
        console.log(error);
      });
  }

  private loadQuestionnaires() {
    this.workflowService
      .loadQuestionnaires()
      .then(questionnaires => {
        if (!questionnaires) {
          return;
        }
        this.questionnaires = questionnaires;

        for (const q of questionnaires) {
          this.questionnaireTitles[q.id] = q.meta.title;
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  /**
   * Returns true if a workflow instance is expired
   */
  public isExpired(instance: WorkflowInstance): boolean {
    const now = <any>new Date().toJSON();
    const isDone = instance.status === 'submitted' || instance.status === 'canceled';
    const isExpired = instance.validUntil && instance.validUntil < now;

    if (isDone) {
      return false;
    }

    return isExpired;
  }

  public getQuestionnaireTitle(uid: string): string {
    if (this.questionnaireTitles[uid] && this.questionnaireTitles[uid][this.locale]) {
      return this.questionnaireTitles[uid][this.locale];
    }
    return '';
  }

  private getQueryFilter() {
    const filter: any = {};
    const like = (value: string) => ({ $regex: value, $options: 'i' });
    const equal = (value: string) => ({ $eq: value });
    const notEqual = (value: string|boolean) => ({ $ne: value });

    if (this.filter.status) {
      filter.status = equal(this.filter.status);
    }

    if (this.filter.patient) {
      const likeValue = like(this.filter.patient);
      filter['$or'] = [
        'firstName',
        'lastName',
        'birthDate',
        'customerNr',
        'caseNr',
        'pseudoId',
        'location',
        'status',
        'address'
      ].map(key => ({ [`client.${key}`]: likeValue }));
    }

    // Special handling for externStatus "open" to include records without externStatus
    if (this.filter.externStatus) {
      if (this.filter.externStatus === 'open') {
        // Include both "open" and undefined/null values
        filter['$or'] = [
          { externStatus: equal('open') },
          { externStatus: { $exists: false } },
          { externStatus: null }
        ];
      } else {
        // For other values, use exact matching
        filter.externStatus = equal(this.filter.externStatus);
      }
    }

    if (this.filter.workflow) {
      const likeValue = like(this.filter.workflow);
      filter[`workflow.name.${this.locale}`] = likeValue;
    }

    if (this.filter.questionnaires && this.filter.questionnaires.length) {
      filter['workflow.stages.questionnaires'] = {
        $all: this.filter.questionnaires
      };
    }

    return filter;
  }

  private getQueryOpts() {
    const queryOpts = {
      limit: this.displayPerPage,
      offset: (this.gridPageNumber - 1) * this.displayPerPage,
      sort: ''
    };
    if (this.filter.sort) {
      queryOpts.sort = this.filter.order === 'asc' ? this.filter.sort : '-' + this.filter.sort;
    }
    return queryOpts;
  }

  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }

  public hasValidExports(submission: any) {
    const exports = submission.exports;
    const isArray = Array.isArray(exports);
    const hasElements = isArray && exports.length > 0;
    const hasObjects = hasElements && typeof exports[0] === 'object';
    const hasExpectedFormat = hasObjects && !!exports[0].type && !!exports[0].fileName;
    return hasExpectedFormat;
  }

  public onDownload(exportFileName: string) {
    this.assetsBaseUri && window.open(`${this.assetsBaseUri}/${exportFileName}`, '_blank');
  }

  public onOpenMediaCenterModal(submission: { bucketId?: string }) {
    this.mediaCenterService &&
    this.mediaCenterService.openModal({
      bucketId: submission.bucketId,
      disableUpload: true,
      enableZipDownload: true
    });
  }

  public onOpenLink(link: string) {
    link && window.open(link, '_blank');
  }

  public onExternStatusChange(instance: WorkflowInstance, status: string) {
    this.workflowService.updateWorkflowInstanceExternStatus(instance.id || instance._id, status).catch(error => {
      console.error(error);
      alert(this._(KEYS.GRID.ERROR_LOADING_WORKFLOWS));
    });
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
